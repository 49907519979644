<template>
  <div>

    <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/BnPFullLogo.png"/>
        </v-avatar>
      FAQ Config
      </v-card-title>
       <v-card elevation="0" class="siteconfigcard" v-if="QuickEditSections.length > 0">
        <v-card-subtitle class="mediumoverline">
          General
      </v-card-subtitle>
      <v-card-text >
          <QuickEditComponent :System="System" :SystemEntities="SystemEntities" :Record="System" :QuickEditSections="QuickEditSections"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect" @SelectImage="SelectImage"
            @AddGallerySubcolImages="AddGallerySubcolImages"
            />
      </v-card-text>
      <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn @click="UpdateQuickEdits()" dark color="success">
              Save
          </v-btn>
      </v-card-actions>
      </v-card>
      <v-card elevation="0" class="siteconfigcard" >
        <v-card-subtitle class="mediumoverline">
          Moderators
      </v-card-subtitle>
      <v-card-text>
          <ModeratorsConfig :System="System" :SystemEntities="SystemEntities"
          :SiteModerators="SiteModerators" :ModerationItems="['FAQ','Public FAQ']"
          />
    </v-card-text>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Recent Activity
      </v-card-subtitle>
      <v-card-text >
         Per Module
      </v-card-text>
      </v-card>
      
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import ModeratorsConfig from '@/components/SuiteBuilder/Main/ModeratorsConfig'
import QuickEditComponent from '@/components/Database/Fields/Input/QuickEditComponent'
export default {
    props: ['System','SystemEntities','SiteModerators'],
    components: {InfoSheet,ModeratorsConfig,QuickEditComponent},
    data() {
        return {
            SystemTickets: [],
            RoadmapItems: [],
            elmnt:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,
                // Prominent: true								
            },	
        }
    },	
    computed:{
        
        CurrentEntityFields(){
            console.log('SystemConfigEntity',this.SystemConfigEntity)
            return this.SystemConfigEntity.AllFields
            // we canot obejct assign or else things like lastassignedinterger on dyn options et wn't work, keep reassigning to previous
            //what we will do it call the field value, so i changes are canclled, reset to stored value of system, or systme entity alike. yeah..I think. yes 
            //okay sure so we modify state, but reassign abck to system props, and like when we alter dynamic option sets, we alter the field..should be okay?
            //.map(field => {
            //     let fieldobj = Object.assign({},field)
            //     return fieldobj
            // })
        },
        SystemConfigEntity(){
            return this.$store.state.SystemConfigEntity
        },
        QuickEditSections(){
            //TBD
          return [
          ]
        },
        QuickEditFields(){
            return this.QuickEditSections.filter(sect => {
                return sect.Fields
            }).map(sect => {
                return sect.Fields.map(field => {
                    return field.id
                })
            }).flat()
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        NonGuestUsers(){
            return this.$store.state.UsersArray.filter(user => {
                return !user.IsGuestUser
            })
        },
        PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
        Has_Public_Signup(){
         return this.PluginDataBase && this.PluginDataBase.Public_Signup ? this.PluginDataBase.Public_Signup.Active : false
       },
        WebsiteisActive(){
            return this.$store.state.WebsiteisActive
        },
        SocialNetworkisActive(){
            return this.$store.state.SocialNetworkisActive
        },
        DatabaseisActive(){
            return this.$store.state.DatabaseisActive
        },
        DocumentationisActive(){
            return this.$store.state.DocumentationisActive
        },
         ActiveDB(){
            return db
        },
        IncompleteRoadmapItems(){
            return this.RoadmapItems.filter(item => {
                return item.Status && item.Status.Name !== 'InActive'
            })
        },
        OpenSystemTickets(){
            return this.SystemTickets.filter(ticket => {
                return ticket.Status && ticket.Status.Name !== 'InActive'
            })
        },
        HighlightedAlertElements(){
            let items = [
                {Title: 'Incomplete System Tickets',Array: 'OpenSystemTickets', TableName: 'System Tickets', TablePath: 'SystemTickets',IncompleteName: 'Open'},
                {Title: 'Incomplete Roadmap Items',Array: 'IncompleteRoadmapItems', TableName: 'Roadmap Items', TablePath: 'Roadmap',IncompleteName: 'Incompleted'},
            ]
            items.map(item => {
               if(this[item.Array] && this[item.Array].length > 0){
                        item.Description = 'You have a total of '+this[item.Array].length+' '+item.IncompleteName+' '+item.TableName+'. View them now?'
                        item.Color = 'red'
                        item.AlertIcon = 'mdi-alert'
                        item.HasActionButton = true
                        item.RoutePath = '/'+item.TablePath
                    }
                    else if(!this[item.Array] || this[item.Array] && this[item.Array].length === 0){
                        item.Description = 'No '+item.TableName+' '+item.IncompleteName+'.'
                        item.Color = 'green'
                        item.AlertIcon = 'mdi-check'
                    }
                return item
            })
            return items.map(item => {
                let elmnt = Object.assign({},this.elmnt)
                elmnt.Title = item.Title
                elmnt.Description = item.Description
                elmnt.Color = item.Color
                elmnt.AlertIcon = item.AlertIcon
                if(item.HasActionButton){
                    elmnt.HasActionButton = item.HasActionButton
                    elmnt.RoutePath = item.RoutePath
                }
                return elmnt
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
    },
    methods:{
        
        PrepQuickFieldSection(fields){
        return fields.map(field => {
            let fieldobj = this.CurrentEntityFields.find(obj => obj.id === field)
            //console.log(field,fieldobj,this.System)
            if(fieldobj.Type === 'Date' && typeof fieldobj.DateMenu === 'undefined'){
              fieldobj.DateMenu = false
              if(typeof this.System[fieldobj.Name] !== 'undefined' && typeof this.System[fieldobj.Name].toDate !== 'undefined'){
                let dateobject = this.System[fieldobj.Name].toDate()
                this.System[fieldobj.Name] = format(dateobject,'yyyy-MM-dd')
              }
            }
            if(fieldobj.Type === 'Calculated Field'){
              this.System[fieldobj.Name] = this.PerformedCalculation(this.System,fieldobj)
            }
            if(fieldobj.Type === 'Option Set' && fieldobj.IsDynamic && typeof this.System[fieldobj.Name] !== 'undefined'){
                //console.log(this.System[fieldobj.Name])
                fieldobj = JSON.parse(JSON.stringify(this.System[fieldobj.Name]))
            }
            return fieldobj
          })
      },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
        
        UpdateQuickEdits(){
            let values = this.QuickEditFields
            let updatedobj = {}
            values.map(val => {
                if(typeof this.System[val] !== 'undefined'){
                    updatedobj[val] = this.System[val]
                }
            })
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update(updatedobj).then(updatedsysobj => {

            })           
        },
        UpdateAllowNotificaitonDisable(){
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
                Allow_Notification_Disable: this.System.Allow_Notification_Disable,
            })
        },  
        NoPathFilterRoutePass(route){
        let routernonroutes = ['404','PasswordReset','UserInvite','ExpiredLink','404','landingpagesingle','PermissionError','login']
        //SiteEventSocialSingle and sarrticles etc excluded from route.meta.type Social
        let mainnonroutes = ['FeaturedMember','BusinessMember','SiteMemberSingle','SitePostSingle','noticesingle','ClassifiedSingle','ClassifiedEnquiry',
        'GroupArticle','GroupMeetup','GroupDashboard','GroupBlog','GroupForum','GroupPage','GroupPoll','GroupSingle','GroupEdit','GroupPageEditor','GroupNewPoll','GroupNewArticle','GroupNewMeetup','GroupNewBlog',
        'GroupNewPoll','GroupWebFormEditor','GroupWebFormBuilder','GroupPageBuilder',
        'LinkedInAuth','TeamMemberActivities','DashboardBuilder','PipelineManagement','PageEditor','newbannerad','newlandingpage','noticenew','MyActivitiesbyType','MyTeamMemberActivities',
        'TableViewer','EntryViewer','WebFormEditor','SurveyBuilder','DirectoryBuilder','PDFExportFunctionBuilder','WikiCollectionBuilder','DataImport','SystemTrainingDocument','FeaturedMemberEdit',
        'CustomDashboard'
        ]
        if(!routernonroutes.includes(route.name) && !mainnonroutes.includes(route.name)){
         return !route.meta || route.meta && route.meta.RouteEventID !== 1028 && route.meta.RouteEventID !== 1027 && route.meta.TopicCategory !== 'Social Page' &&
         route.meta.type !== 'Social' && route.meta.type !== 'Classified Listing Viewer' && route.meta.type !== 'Wiki Editor' && route.meta.type !== 'Wiki Viewer'
         && !route.meta.DataSingleBuilder && !route.meta.ClassifiedListingBuilder 
        }
        else{
          return false
        }        
      },
        RouteOptions(field){
            let routes = this.$router.getRoutes()
            return routes.filter(route => {
                if(field.PathFilter){
                    if(field.PathFilter === 'None'){
                    return this.NoPathFilterRoutePass(route)
                    }
                    else if(field.PathFilter === 'Non Guest Routes'){
                    return route.name === 'MyDashboard' || route.name === 'MyProfile' || route.name === 'Home' || route.meta && route.meta.RouteEventID === 1027 || route.meta && route.meta.TopicCategory === 'Social Page'
                    }
                    else if(field.PathFilter === 'Guest Routes'){
                      //we had this.System.Guests_can_Social && route.meta && route.meta.RouteEventID === 1027 but then typically "store" page not allowed
                    return route.name === 'MyAccount' || route.meta && route.meta.RouteEventID === 1027 || this.System.Guests_can_Social && route.meta &&  route.meta.TopicCategory === 'Social Page'
                    }
                    else if(field.PathFilter === 'Public Pages'){
                        return route.meta && route.meta.RouteEventID === 1028
                    }
                    else if(field.PathFilter === 'Internal Pages'){
                      return route.meta && route.meta.RouteEventID === 1027 || route.meta && route.meta.TopicCategory === 'Social Page'
                    }
                }                
                else{
                    return route
                }
            })
        },
        GetArrayData(query,array){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    if(array === 'GroupLandingPageViews'){                        
                        recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        //console.log('GroupLandingPageViews',recordobj)
                    }
                    if(array === 'PublicPageViews' || array === 'SocialPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    if(array === 'LandingPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join('')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    this[array].push(recordobj)
                }
                })
            })
        },
    }
}
</script>

<style>

</style>



